/* Built In Imports */
import { useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import PageConfig from '@lb/Common/PageConfig';
import RenderSections from '@lb/Common/RenderSections';
import LBFooter from '@lb/components/Footer/LBFooter';
import WrapLayout from '@lb/components/Layout/WrapLayout';
import Breadcrum from '@lb/components/Navigation/Breadcrum';
import HeadComponent from '@lb/components/Utility/HeadComponent';
import { AuthContext } from '@store/auth-context';

/* Services */
import { getLocale } from '@lb/components/Utility/Shared/SharedService';
import {
  fetchFooterDetailData,
  fetchHeaderMenuData,
} from 'services/commonService';
import { fetchDetails, getLandingPaths } from 'services/lbService';

/* Styles */

/**
 *
 * @param {object} pageData
 * @param lang
 * @param region
 * @returns
 */

const LbLang = ({
  pageData,
  region,
  lang,
  headerMenuData,
  topMenuData,
  footerData,
}) => {
  const authContext = useContext(AuthContext);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isHighResScreen: false,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isHighResScreen:
        window.innerHeight > 768 &&
        window.innerHeight * window.innerWidth > 298000,
    });
  };

  useEffect(() => {
    document.addEventListener('resize', handleResize);
    handleResize();
    return () => document.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    authContext.RegionAndLang(region, lang);
  }, [authContext, region, lang]);

  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      {pageData?.breadcrumbs?.length > 0 && (
        <Breadcrum
          page={pageData.breadcrumbs}
          alsoin={pageData.alsoin}
          url=""
          region={region}
          lang={lang}
        />
      )}
      <Box
        as="section"
        className={
          windowSize?.isHighResScreen
            ? 'container-article mainScrollWrap'
            : 'container-article'
        }
        p="0"
        background={`url(${config.imgPath}/d/46272/1682095879-white-texture-final.png)`}
      >
        {pageData?.body?.map(section => {
          section.viewH = windowSize?.isHighResScreen;
          return (
            <RenderSections
              pageData={pageData}
              section={section}
              headerMenuData={headerMenuData}
              topMenuData={topMenuData}
              region={region}
              lang={lang}
              pType="home"
              key={nanoid(4)}
            />
          );
        })}
        <Box height="max-content !important" bgColor="#000">
          <LBFooter
            region={region}
            language={lang}
            footerData={footerData}
            pageConfig={pageData?.pageConfig}
            page={'home'}
          />
        </Box>
      </Box>
      <PageConfig isLikeVisible={false} pageData={pageData} />
      <style jsx global>
        {`
          html,
          body {
            height: 100%;
            overflow: ${windowSize?.isHighResScreen ? 'hidden' : 'auto'};
            margin: 0px;
            padding: 0px;
          }
          .mainScrollWrap {
            overflow-y: ${windowSize?.isHighResScreen ? 'auto' : 'hidden'};
            height: ${windowSize?.isHighResScreen ? '100vh' : 'auto'};
            height: ${windowSize?.isHighResScreen ? '100svh' : 'auto'};
            scroll-snap-type: ${windowSize?.isHighResScreen
              ? 'y mandatory'
              : ''};
            box-sizing: border-box;
            scroll-snap-align: ${windowSize?.isHighResScreen ? 'start' : ''};
          }
          .mainScrollWrap > div:nth-child(1n) {
            scroll-snap-align: ${windowSize?.isHighResScreen ? 'start' : ''};
            scroll-snap-stop: ${windowSize?.isHighResScreen ? 'always' : ''};
            height: ${windowSize?.isHighResScreen ? '100vh' : 'auto'};
            height: ${windowSize?.isHighResScreen ? '100svh' : 'auto'};
          }
        `}
      </style>
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {
  const lbPaths = config.USE_LOCAL_URL
    ? [
        `/linga-bhairavi/in/en`,
        `/linga-bhairavi/np/en`,
        `/linga-bhairavi/global/en`,
        `/linga-bhairavi/np/ne`,
      ]
    : await getLandingPaths('lvl0', '/linga-bhairavi/');
  return {
    paths: lbPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, lang },
}) => {
  const topMenuData = await fetchHeaderMenuData(
    getLocale(region, lang, 'lb'),
    'lbtopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, lang, 'lb'),
    'lbmainmenu'
  );
  const footerData = await fetchFooterDetailData(
    getLocale(region, lang, 'lb'),
    'lbfooter'
  );
  const pageData = await fetchDetails(region, lang, '/', {
    preview,
    data: previewData,
  });

  /* console.log('sdsd', pageData); */

  return {
    props: {
      headerMenuData: headerMenuData?.data || null,
      footerData: footerData?.data || null,
      headerVisible: false,
      topMenuData: topMenuData,
      pageData: pageData || null,
      isPreview: preview ? true : false,
      region: region,
      lang: lang,
      footerVisible: false,
      menuType: 'lb',
    },
  };
};

export default LbLang;
